import {css} from '@emotion/react'
import {PageProps} from 'gatsby'
import React, {useContext} from 'react'

import {articleMaxWidth} from '../constants'
import UserContext from '../providers/UserContext'
import styles from '../styles'
import {BasePageContext} from '../types'
import SignInModal from '../components/SignInModal'
import CopyableValue from '../components/CopyableValue'

import PageWithHeader from './PageWithHeader'

interface AccountPageProps extends PageProps {
  pageContext: BasePageContext
}

const mainCss = css`
  max-width: ${articleMaxWidth}px;
  width: 100%;
  padding: 27px 27px 0 27px;
  margin: 0 auto;
`

export default function AccountPage({
  location,
  pageContext: {pageConfig},
}: AccountPageProps): JSX.Element {
  const {user, _state: userState} = useContext(UserContext)

  return (
    <PageWithHeader location={location} pageConfig={pageConfig}>
      {userState === 'known' && !!user?.email && (
        <main css={mainCss}>
          <h1 css={styles.form.h1}>Account Details</h1>

          <label css={styles.form.label} htmlFor="email-input">
            Email Address
            <input
              id="email-input"
              css={styles.form.input}
              type="text"
              disabled
              value={user.email}
            />
          </label>
          {user.refreshToken && (
            <CopyableValue
              value={user.refreshToken}
              label="Refresh Token"
              description="Used to generate new temporary Access Tokens"
            />
          )}
          {user.token && (
            <CopyableValue
              value={user.token}
              label="Access Token"
              description="Used to programmatically access the API"
            />
          )}
        </main>
      )}
      <SignInModal isOpen={userState === 'known' && !user?.email} />
    </PageWithHeader>
  )
}
